#addpdf{
    border-bottom: 1px solid black;
    padding: 10px;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  
    text-transform: unset !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
 
    padding: 10.5px 14px !important;
}