.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {

    color: #6257CD !important;
}












.categoryname {
    background-color: #6257CD !important;
    width: 200px;
    height: 6.5vh;
    font-size: 15px !important;
    /* margin-left: 4%; */
    font-weight: 400 !important;
    /* border-radius: 5px 5px; */
}

.categorynames {
    background-color: #6257CD !important;
    width: 184px;
    height: 45px;
    font-weight: 400 !important;
    color: white !important;
    font-size: 15px !important;
    /* border-radius: 4%; */
}

.savequstion {
    background-color: #6257CD;
    width: 200px;
    height: 5vh;
    margin-left: 54%;
    font-weight: 800 !important;
    border-radius: 5px 5px;
    margin-bottom: 2%;
    text-transform: capitalize !important;
}

.saveandcnt {
    background-color: #6257CD;
    width: 200px;
    height: 5vh;
    /* margin-left: 0%; */
    font-weight: 800 !important;
    border-radius: 5px 5px;
    margin-bottom: 2%;
    text-transform: capitalize !important;
    float: right;
    /* margin-right: 26%; */
}

.saveandcnts {
    /* background-color: #6257CD; */
    width: 14%;
    height: 5vh;
    margin-left: 60%;

    font-weight: 800 ;
    border-radius: 4%;
    margin-bottom: 2%;
    text-transform: capitalize !important;
    margin-top: 4%;
}

.boxdesign {
    /* background-color: #6257CD; */
    /* width: 15%; */
    /* height: 5vh; */
    margin-left: 4%;
    /* font-weight: 800; */
    margin-top: 10px;
    /* overflow: "hidden"; */
    /* border-radius: 4%; */
}

.boxdesignquiz {
    /* background-color: #6257CD; */
    width: 80% !important;
    height: 100% !important;
    margin-left: 4% !important;
    font-weight: 800 !important;
    margin-top: 10px !important;
    /* background: linear-gradient(180deg, #FFFFFF 48.58%, rgba(220, 247, 255, 0.71) 97.76%); */

    /* overflow: "hidden"; */
    /* border-radius: 4%; */
}

.boxnewdesign {
    /* background-color: #6257CD; */
    /* padding: 110px 114px 214px 425px; */
    /* width: 100% !important; */
    /* height: 100% !important; */
    /* margin-left: 4%; */
    /* font-weight: 800 !important; */
    /* margin-top: 10px;/ */
    /* margin: 1%; */
    overflow: hidden !important;
    border: 1px solid #888
        /* border-radius: 4%; */
}

.addmininame {
    /* background-color: #6257CD; */
    /* width: 30%;
    height: 4vh; */
    margin-left: 3%;
    /* margin-top: 4%; */
    font-weight: 800 !important;
    border-radius: 4%;
    display: flex;
    flex-direction: row;
}

.addmininamess {
    /* background-color: #6257CD; */
    width: 30%;
    height: 4vh;
    margin-top: 4%;
    font-weight: 800 !important;
    border-radius: 4%;
    display: flex;
    flex-direction: row;
}

.addmininames {
    /* background-color: #6257CD; */
    width: 100%;
    height: 4vh;
    margin-top: 4%;
    font-weight: 800 !important;
    border-radius: 4%;

}

.categoryadd {
    background-color: #6257CD !important;
    width: 100%;
    right: 70px;
    height: 5vh;
    color: white;
    flex-direction: row !important;
    display: flex !important;
}

.btns {
    color: rgb(248, 248, 248);
    background-color: #6257CD !important;
    border-radius: 5px !important;
}

.categoryhead {
    background-color: #E8EAFE;
    border-radius: 100px !important;
}

.boxset {
    box-shadow: -2px -2px 20px #BFBFBF, 2px 2px 20px #BFBFBF;
}

.classname {
    /* box-shadow: -2px -2px 20px #BFBFBF, 2px 2px 20px #BFBFBF; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-end; */
    padding: 32px 16px;
    /* gap: 16px; */
    overflow: hidden !important;
    /* position: relative; */
    width: 1020px;
    height: 471px;

    /* background: #; */
    background-color: #ffffff;
    /* box-shadow: -4px -4px 10px #999999, 4px 4px 10px #999999; */
    border-radius: 4px;
}

.border {
    width: 130% !important;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    background-color: #ffffff;
    margin-left: 2.5%;
    margin-top: 5% !important;
    border: 0.1px solid #ececec;
}

.categorybody {
    box-sizing: border-box;
    width: 1046px !important;
    height: 21px;
    background: linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%);
    border: 0.830269px solid #C0ADAD;
}

.Notification {
    color: var(--nuetrals-nuetrals-n-400, #4A4958) !important;
    /* Body/B1 - Reg */
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;


}

.Notification1 {

    /* Body/B1 - Reg */
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;

}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {

    padding: 5px !important;
}

/* 
Staff Selection Commission
Examination name
SSC MTS (Multi-Tasking Staff) and Havaldar (CBIC & CBN) 2023
Exam conducting for
Group ‘C’ posts
Application dates
30th June to 21st July 2023
Application mode
Online
Vacancy
MTS:- 1198 (approx.)
Havaldar in CBIC and CBN:- 360
Salary
Pay Level-1 as per Pay Matrix of 7th Pay Commission.
SSC MTS Notification 2023
Official Website
https://ssc.nic.in/
SSC MTS Application Form 2023
For more details:=https://exampur.com/exam-content/ssc-mts-exam-notification-out-2023-eligibility-criteria-vacancy/#ssc-mts-recruitment-2023
SSC MTS Application Form 2023: */